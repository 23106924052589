import { computed } from 'vue'
import { CUSTOM_HELPERS } from '@/utils/validator/customValidator'
import { RulesValidationScroll } from '@/utils/validator/RulesValidationScroll'
import { RUB_NAME } from '@/utils/consts'
import { useCalculateDifferenceStep } from '@/use/Base/useCalculateDifferenceStep'
import { useStore } from 'vuex'
import { findBy } from '@/utils/array'

export function useValidateField(formData, scrollErrorHandler, lot) {
  const store = useStore()
  const getCurrentCurrencyName = computed(() => store.getters['lot/getCurrentCurrencyName'])
  // for scroll
  const paramsScroll = {
    block: 'lot-position',
    id: formData?.id,
    cb: scrollErrorHandler
  }
  const withScrollTo = (field, message, validator) => {
    return CUSTOM_HELPERS.withMessageScroll(
      message,
      {
        ...paramsScroll,
        field
      },
      validator
    )
  }
  const requiredWithScrollTo = field => RulesValidationScroll.createRequiredValid({ field, ...paramsScroll })

  const lastOffer = computed(() => {
    return store.getters['lot/getLastPrice'].last_offers?.find(offer => offer.answers.position.id === formData.id)
  })

  // For table
  const getPriceMinMaxCustom = (pos, offer) => {
    const minStep = +lot?.min_step
    const maxStep = +lot?.max_step

    if (!minStep || !maxStep) return null
    const lastCurrencyName = offer ? offer.lot_currency.name : lot?.currency?.name
    const isSameCurrency = lastCurrencyName === getCurrentCurrencyName.value
    let startPrice = offer ? +offer.price : +pos.start_price

    if (!isSameCurrency && getCurrentCurrencyName.value) {
      const lastCurrencyIsRUB = lastCurrencyName === RUB_NAME
      let priceStartRUB
      if (!lastCurrencyIsRUB) {
        const rate = getCentralBankRateBy(lastCurrencyName)
        priceStartRUB = rate * startPrice
      } else {
        priceStartRUB = startPrice
      }

      const currentCurrencyIsRUB = getCurrentCurrencyName.value === RUB_NAME
      let priceStartCurrency
      if (!currentCurrencyIsRUB) {
        const rate = getCentralBankRateBy(getCurrentCurrencyName.value)
        priceStartCurrency = priceStartRUB / rate
      }

      startPrice = priceStartCurrency || priceStartRUB
    }

    const isPercent = lot.is_procent
    const difference = useCalculateDifferenceStep({ minStep, maxStep, startPrice, isPercent })

    return {
      min: difference.min,
      max: offer ? difference.max : Number.isInteger(startPrice) ? startPrice : startPrice.toFixed(2)
    }
  }

  const getPriceErrorMsgCustom = (min, max, offer) => {
    if (!min) {
      return 'Меньше 0'
    }

    return `От ${min} до ${max}`
  }

  const getPriceMinMax = computed(() => {
    const minStep = +lot?.min_step
    const maxStep = +lot?.max_step

    if (!minStep || !maxStep) return null

    const lastCurrencyName = lastOffer.value ? lastOffer.value.lot_currency.name : lot?.currency?.name

    const isSameCurrency = lastCurrencyName === getCurrentCurrencyName.value
    let startPrice = lastOffer.value ? +lastOffer.value.price : +formData.start_price

    if (!isSameCurrency && getCurrentCurrencyName.value) {
      const lastCurrencyIsRUB = lastCurrencyName === RUB_NAME
      let priceStartRUB
      if (!lastCurrencyIsRUB) {
        const rate = getCentralBankRateBy(lastCurrencyName)
        priceStartRUB = rate * startPrice
      } else {
        priceStartRUB = startPrice
      }

      const currentCurrencyIsRUB = getCurrentCurrencyName.value === RUB_NAME
      let priceStartCurrency
      if (!currentCurrencyIsRUB) {
        const rate = getCentralBankRateBy(getCurrentCurrencyName.value)
        priceStartCurrency = priceStartRUB / rate
      }

      startPrice = priceStartCurrency || priceStartRUB
    }

    const isPercent = lot.is_procent
    const difference = useCalculateDifferenceStep({ minStep, maxStep, startPrice, isPercent })

    return {
      min: difference.min,
      max: lastOffer.value ? difference.max : Number.isInteger(startPrice) ? startPrice : startPrice.toFixed(2)
    }
  })

  const getPriceErrorMsg = computed(() => {
    if (!getPriceMinMax.value?.min) {
      return 'Цена не может быть меньше 0'
    }
    return `Цена позиции не должна быть ниже ${getPriceMinMax.value.min} и выше ${getPriceMinMax.value.max} исходя от ${lastOffer.value ? 'предыдущей' : 'стартовой'} цены`
  })

  const priceValidator = val => {
    if (!getPriceMinMax.value?.min) {
      return !val || +val > 0
    }

    if (val && getPriceMinMax.value.max > 0) {
      return !(Math.floor(+val) < getPriceMinMax.value.min || Math.floor(+val) > getPriceMinMax.value.max)
    }

    return true
  }

  function getCentralBankRateBy (currencyName) {
    const centralBankCurrentCurrency = findBy('Name', currencyName, lot?.centrobank_currency)
    return +centralBankCurrentCurrency?.Value?.replace(/,/, '.')
  }

  return {
    requiredWithScrollTo,
    withScrollTo,
    getPriceErrorMsg,
    priceValidator,
    paramsScroll,
    getPriceMinMaxCustom,
    getPriceErrorMsgCustom
  }
}

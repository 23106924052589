export function useCalculateDifferenceStep ({ minStep, maxStep, startPrice, isPercent }) {
  let min
  let max

  if (isPercent) {
    const percentageFormula = (step) => startPrice - (startPrice * step / 100)
    min = percentageFormula(maxStep)
    max = percentageFormula(minStep)
  } else {
    min = startPrice - maxStep
    max = startPrice - minStep
  }

  return {
    min: Number.isInteger(min) ? min : min.toFixed(2),
    max: Number.isInteger(max) ? max : max.toFixed(2)
  }
}
